import { DcPlan } from "../types/plan";

export function getPricingData(onSubscribeClicked?: (plan: DcPlan) => Promise<void>, isPlanChosen?: boolean) {
  const t = useNuxtApp().$i18n.t;

  const buttonLabel = isPlanChosen ? t("pricing.actions.subscribe") : t("pricing.actions.startTrial");

  return {
    plans: [
      {
        id: DcPlan.Essential,
        title: t("pricing.plans.essential.title"),
        description: t("pricing.plans.essential.description"),
        price: t("pricing.plans.essential.price"),
        discountedPrice: t("pricing.plans.essential.discountedPrice"),
        yearlyPrice: t("pricing.plans.essential.yearlyPrice"),
        discountedYearlyPrice: t("pricing.plans.essential.discountedYearlyPrice"),
        cycle: t("pricing.plans.essential.cycle"),
        badge: t("pricing.plans.essential.badge"),
        highlight: false,
        button: {
          label: buttonLabel,
          click: async () => {
            if (!onSubscribeClicked)
              throw createError("onSubscribeClicked is not defined in useLandingCopy");

            await onSubscribeClicked(DcPlan.Essential);
          },
        },
        features: t("pricing.plans.essential.features").split("\n").filter(Boolean),
      },
      {
        id: DcPlan.Pro,
        title: t("pricing.plans.pro.title"),
        description: t("pricing.plans.pro.description"),
        price: t("pricing.plans.pro.price"),
        discountedPrice: t("pricing.plans.pro.discountedPrice"),
        yearlyPrice: t("pricing.plans.pro.yearlyPrice"),
        discountedYearlyPrice: t("pricing.plans.pro.discountedYearlyPrice"),
        cycle: t("pricing.plans.pro.cycle"),
        highlight: true,
        badge: t("pricing.plans.pro.badge"),
        button: {
          label: buttonLabel,
          click: async () => {
            if (!onSubscribeClicked)
              throw createError("onSubscribeClicked is not defined in useLandingCopy");

            onSubscribeClicked(DcPlan.Pro);
          },
        },
        features: t("pricing.plans.pro.features").split("\n").filter(Boolean),
      },
      {
        id: DcPlan.Enterprise,
        title: t("pricing.plans.enterprise.title"),
        description: t("pricing.plans.enterprise.description"),
        price: t("pricing.plans.enterprise.message"),
        discountedPrice: "",
        yearlyPrice: "",
        discountedYearlyPrice: "",
        cycle: "",
        highlight: false,
        button: {
          label: t("pricing.plans.enterprise.buttonLabel"),
          click: () => {
            if (!onSubscribeClicked)
              throw createError("onSubscribeClicked is not defined in useLandingCopy");

            onSubscribeClicked(DcPlan.Enterprise);
          },
        },
        features: t("pricing.plans.enterprise.features").split("\n").filter(Boolean),
      },
    ],
  };
}
