<script setup lang="ts">
import { OrganizationUserRole } from "docuchatcommontypes";
import { DcPlan, DcPlanCycle } from "~/types/plan";

withDefaults(defineProps<{ preventClose?: boolean; }>(), {
  preventClose: true,
});

const { $sentry } = useNuxtApp();
const { t } = useI18n();
const toast = useToast();

const { user, canUseApp, isPlanExpired } = storeToRefs(useUserStore());
const { updatePlan, getBillingPortalUrl, fetchUserData } = useUserStore();
const { signOut } = useAuthentication();
const { isOnPostcheckoutPage } = useNavigation();

// We show the modal if the user cannot use the app and is not on the post checkout page.
const open = computed(() => !isOnPostcheckoutPage() && !canUseApp.value && user.value?.organizationUserRole === OrganizationUserRole.SuperAdmin);

const { plans } = getPricingData(handleSubscribeClick, user.value?.isPlanChosen);
const billYearly = ref(false);

const header = computed(() => {
  if (isPlanExpired.value) {
    return {
      title: t("pricing.header.expired.title"),
      description: t("pricing.header.expired.description"),
    };
  }
  else {
    if (!user.value?.isPlanChosen) {
      return {
        title: t("pricing.header.getStarted.title"),
        description: t("pricing.header.getStarted.description"),
      };
    }
    else {
      return {
        title: t("pricing.header.updatePlan.title"),
        description: t("pricing.header.updatePlan.description"),
      };
    }
  }
});

async function handleSubscribeClick(plan: DcPlan) {
  if (plan === DcPlan.Enterprise) {
    const subject = t("pricing.enterpriseEmail.subject");
    const mailTo = t("pricing.enterpriseEmail.to");
    const mailToWithSubject = `${mailTo}?subject=${encodeURIComponent(subject)}`;
    window.open(mailToWithSubject, "_blank");
    return;
  }

  sendProcessingNotification();

  try {
    // If this is the first time the user is choosing a plan, we set the plan to trial.
    const processedPlan = !user.value?.isPlanChosen ? DcPlan.Trial : plan;
    const redirectUrl = await updatePlan(processedPlan, billYearly.value ? DcPlanCycle.Annual : DcPlanCycle.Monthly);

    // Subscribe
    if (redirectUrl) {
      await navigateTo(redirectUrl, { external: true });
    }
    // Trial
    else if (processedPlan === DcPlan.Trial) {
      await fetchUserData();
      toast.add({
        title: t("pricing.toasts.trialStarted.title"),
        description: t("pricing.toasts.trialStarted.description"),
        icon: "i-carbon-lightning",
      });
      throwConfetti();
    }
    else {
      await fetchUserData();
      $sentry.captureError("No redirect URL was received after subscription request, and it was not a trial plan.", {
        extra: {
          plan,
          processedPlan,
          billYearly: billYearly.value,
        },
      });
    }
  }
  catch (error: any) {
    toast.add({
      title: t("pricing.toasts.error.title"),
      description: error.message || t("pricing.toasts.error.description"),
      icon: "i-carbon-warning",
      color: "red",
    });
  }
}

async function handleOpenBillingPortal() {
  sendProcessingNotification();

  try {
    const url = await getBillingPortalUrl();
    await navigateTo(url, { external: true });
  }
  catch (error: any) {
    toast.add({
      title: t("pricing.toasts.error.title"),
      description: error.message || t("pricing.toasts.error.description"),
      icon: "i-carbon-warning",
      color: "red",
    });
  }
}

function sendProcessingNotification() {
  toast.add({
    title: t("pricing.toasts.processing.title"),
    description: t("pricing.toasts.processing.description"),
    icon: "i-carbon-time",
  });
}

onMounted(async () => {
  // If the user is coming from the landing page with a subscribe query, we open the modal with the plan selected.
  const { subscribe, billYearly: billYearlyRequested } = useRoute().query;
  if (billYearlyRequested)
    billYearly.value = billYearlyRequested === "true";

  if (subscribe)
    await handleSubscribeClick(subscribe as DcPlan);
});
</script>

<template>
  <UModal
    v-model="open"
    :prevent-close="preventClose"
    :ui="{
      width: 'sm:max-w-6xl',
    }"
  >
    <UCard>
      <div class="my-4">
        <h1 class="text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          {{ header.title }}
        </h1>
        <p class="mt-2 text-center text-base text-gray-500 dark:text-gray-400">
          {{ header.description }}
        </p>
      </div>

      <TheModalSubscribePricingTable
        v-model="billYearly"
        :plans="plans"
      />

      <!-- Footer Actions -->
      <template #footer>
        <div class="flex items-center justify-end gap-4">
          <UButton
            variant="ghost"
            size="xs"
            @click.prevent="signOut"
          >
            {{ t("pricing.actions.signOut") }}
          </UButton>
          <UButton
            v-if="isPlanExpired"
            variant="soft"
            size="xs"
            @click.prevent="handleOpenBillingPortal"
          >
            Billing Portal
          </UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>
